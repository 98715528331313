import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Settings from "../components/Settings"
const SettingPage = () => (
  <Layout login>
    <Seo title="Settings" />
    <Settings />
  </Layout>
)

export default SettingPage
