import React, { useState } from "react"
import Header from "../ReportDashboard/Header"
import { ReportDashboardWrapper } from "../ReportDashboard/style"
import { navigate } from "gatsby"
import ChangePassword from "./changePassword"
import Mfa from "./mfa"
import { ArrowBackIos } from "@material-ui/icons"

function Settings() {
  const getMFA =
    typeof window !== "undefined" && localStorage.getItem("mfa")
  const [showVerification, setShowVerification] = useState(false)
  const [mfaStatus, setMfaStatus] = useState(getMFA || "0")
  return (
    <ReportDashboardWrapper>
      <Header />
      <div className="settings-wrapper">
        <p
          className="back"
          onClick={() => {
            if (!showVerification) {
              navigate("/report-dashboard")
            } else {
              setShowVerification(false)
            }
          }}
        >
          <ArrowBackIos />
          Return to {!showVerification ? "Dashboard" : "Settings"}
        </p>

        <div className="settings-container">
          {!showVerification && <ChangePassword />}
          <Mfa
            showVerification={showVerification}
            setShowVerification={setShowVerification}
            mfaStatus={mfaStatus}
            setMfaStatus={setMfaStatus}
          />
        </div>
      </div>
    </ReportDashboardWrapper>
  )
}

export default Settings
