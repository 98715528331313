import React, { useState } from "react"
import {
  Button,
  CircularProgress,
  Paper,
  IconButton,
  InputAdornment,
  TextField,
  NoSsr,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { Visibility, VisibilityOff } from "@material-ui/icons"
import { isValidPhoneNumber } from "libphonenumber-js"
import { mfaSetup, verifyMfaSetup } from "../../httpRequests/mfa"
import Loadable from "react-loadable"

const useStyles = makeStyles(theme => ({
  input: {
    border: "1px solid #F0F1F7",
    background: "#FCFDFE",
    minWidth: 624,
    fontFamily: "LatoRegular",
    fontSize: 18,
    paddingLeft: "20px",
    marginBottom: 15,
    "@media screen and (max-width: 767px)": {
      minWidth: "100%",
    },
  },
  svg: {
    fill: "rgba(0,0,0,0.5) !important",
  },
}))

const MuiPhoneNumber = Loadable({
  loader: () => import("material-ui-phone-number"),
  loading: CircularProgress,
})

function Mfa(props) {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [currentPW, setCurrentPW] = useState("")
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState("")
  const [showCurrent, setShowCurrent] = useState(false)
  const [phone, setPhone] = useState("")
  const [country, setCountry] = useState("US")
  const {
    showVerification,
    setShowVerification,
    mfaStatus,
    setMfaStatus,
  } = props
  const [verificationCode, setVerificationCode] = useState("")
  const [showStatus, setShowStatus] = useState(false)
  const [maxSent, setMaxSent] = useState(false)

  const onFieldChange = () => {
    setMessage("")
    if (currentPW.trim() !== "") {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  const handlePhoneChange = (e, countryData) => {
    setMessage("")
    setPhone(e)
    setCountry(countryData.countryCode.toUpperCase())
    if (e.length > 8) {
      setMessage("")
      setDisabled(false)
    } else {
      setMessage(
        "The number entered does not match the length for the country selected."
      )
    }
  }

  const validation = () => {
    setMessage("")
    if (isValidPhoneNumber(phone, country)) {
      return true
    } else {
      setMessage("Please enter a valid phone number")
      return false
    }
  }

  const onClickSubmit = async () => {
    setMessage("")
    if (validation()) {
      setLoading(true)
      const response = await mfaSetup({
        password: currentPW,
        phoneNumber: phone,
      })

      if (response.data.statusCode === 200) {
        setSuccess(true)
        setShowVerification(true)
        setMessage("")
      } else {
        setSuccess(false)
        setMessage(response.data.devMessage)
        if (response.data.devMessage.includes("Max send")) {
          setMaxSent(true)
          setMessage("Max attempt reached. Please try again later.")
        }
      }
      setLoading(false)
    }
  }

  const confirmCode = async () => {
    setMessage("")
    if (verificationCode === "") {
      setMessage("Verification code is required")
    } else {
      const res = await verifyMfaSetup({
        status: mfaStatus === "0" ? 1 : 0,
        code: verificationCode,
        phoneNumber: phone,
      })

      if (res.data.statusCode === 200) {
        typeof window !== "undefined" &&
          localStorage.setItem("mfa", mfaStatus === "0" ? 1 : 0)
        const newStatus =
          typeof window !== "undefined" && localStorage.getItem("mfa")
        setMfaStatus(newStatus)
        setSuccess(true)
        setShowStatus(true)
        setCurrentPW("")
      } else {
        setSuccess(false)
        setMessage("Verfication is incorrect")
      }
    }
  }

  return (
    <Paper>
      {!showStatus && <h2>Two-Factor Authentication (2FA)</h2>}
      {!showVerification && (
        <div className="mfa">
          <p>
            Add a layer of security. Require a verification code in addition to
            your password.
            <br /> Status:{" "}
            <span className={mfaStatus === "1" ? "on" : "off"}>
              {mfaStatus === "1" ? "On" : "Off"}
            </span>
          </p>
        </div>
      )}

      <div className="change-pw">
        <div className="error-message">
          {message !== "" && (
            <p className={success ? "success" : "error"}>{message}</p>
          )}
        </div>
        <div className="fields-wrapper">
          {!showVerification ? (
            <>
              <TextField
                className={classes.input}
                fullWidth
                placeholder="Enter current password"
                onChange={e => {
                  setCurrentPW(e.target.value)
                  onFieldChange()
                }}
                type={showCurrent ? "text" : "password"}
                value={currentPW}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setShowCurrent(!showCurrent)}>
                        {showCurrent ? (
                          <Visibility className={classes.svg} />
                        ) : (
                          <VisibilityOff className={classes.svg} />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <NoSsr>
                <MuiPhoneNumber
                  defaultCountry={"us"}
                  disableAreaCodes={true}
                  autoFormat={false}
                  fullWidth
                  countryCodeEditable={false}
                  preferredCountries={["us"]}
                  autoFocus
                  onChange={(e, countryData) =>
                    handlePhoneChange(e, countryData)
                  }
                  InputProps={{
                    className: classes.input,
                  }}
                />
              </NoSsr>
            </>
          ) : showStatus ? (
            <p className="status">
              Two-factor Authentication has been turned{" "}
              {mfaStatus === "1" ? "on" : "off"}.
            </p>
          ) : (
            <TextField
              className={classes.input}
              fullWidth
              placeholder="Enter verification code"
              value={verificationCode}
              onChange={e => setVerificationCode(e.target.value)}
            />
          )}
        </div>

        {!showStatus && (
          <div className="modal-actions">
            {!showVerification ? (
              <Button
                className="confirm"
                variant="contained"
                onClick={onClickSubmit}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress />
                ) : mfaStatus === "1" ? (
                  "Disable"
                ) : (
                  "Enable"
                )}
              </Button>
            ) : !showStatus && maxSent ? (
              <Button
                className="confirm"
                variant="contained"
                onClick={() => {
                  setShowVerification(false)
                  setMaxSent(false)
                }}
              >
                Ok
              </Button>
            ) : (
              <>
                <Button
                  variant="outlined"
                  className="resend"
                  onClick={onClickSubmit}
                >
                  Re-send Code
                </Button>
                <Button
                  className="confirm"
                  variant="contained"
                  onClick={confirmCode}
                >
                  Confirm
                </Button>
              </>
            )}
          </div>
        )}
      </div>
    </Paper>
  )
}

export default Mfa
