import React, { useState } from "react"
import {
  Button,
  CircularProgress,
  Paper,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { changePassword } from "../../httpRequests/chagePassword"
import { Visibility, VisibilityOff } from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  input: {
    border: "1px solid #F0F1F7",
    background: "#FCFDFE",
    minWidth: 624,
    fontFamily: "LatoRegular",
    fontSize: 18,
    paddingLeft: "20px",
    marginBottom: 15,
    "@media screen and (max-width: 767px)": {
      minWidth: "100%",
    },
  },
  svg: {
    fill: "rgba(0,0,0,0.5) !important",
  },
}))

function ChangePassword() {
  const classes = useStyles()
  const [loading, setLoading] = useState(false)
  const [disabled, setDisabled] = useState(true)
  const [currentPW, setCurrentPW] = useState("")
  const [newPW, setNewPW] = useState("")
  const [newPw2, setNewPW2] = useState("")

  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState("")

  const [showCurrent, setShowCurrent] = useState(false)
  const [showNew, setShowNew] = useState(false)
  const [showRe, setShowRe] = useState(false)
  const onClickSubmit = async () => {
    setMessage("")
    if (validation()) {
      setLoading(true)
      const response = await changePassword({
        currentPassword: currentPW,
        newPassword: newPW,
        confirmPassword: newPw2,
      })

      if (response.data.statusCode === 200) {
        setSuccess(true)
        setMessage("Password updated successfully!")
        setCurrentPW("")
        setNewPW("")
        setNewPW2("")
        setDisabled(true)
      } else {
        setSuccess(false)
        setMessage(response.data.devMessage)
        setCurrentPW("")
        setNewPW("")
        setNewPW2("")
        setDisabled(true)
      }
      setLoading(false)
    }
  }

  const validation = () => {
    setMessage("")
    if (newPW !== newPw2) {
      setMessage("Passwords do not match.")
      setDisabled(true)
      return false
    } else if (newPW === currentPW) {
      setMessage("New password cannot be the same as current password.")
      setDisabled(true)
      return false
    } else {
      setMessage("")
      return true
    }
  }

  const onFieldChange = () => {
    setMessage("")
    if (
      currentPW.trim() !== "" &&
      newPW.trim() !== "" &&
      newPw2.trim() !== ""
    ) {
      setDisabled(false)
    } else {
      setDisabled(true)
    }
  }

  return (
    <Paper>
      <h2>Change Password</h2>
      <div className="change-pw">
        <div className="error-message">
          {message !== "" && (
            <p className={success ? "success" : "error"}>{message}</p>
          )}
        </div>
        <div className="fields-wrapper">
          <TextField
            className={classes.input}
            fullWidth
            placeholder="Enter current password"
            onChange={e => {
              setCurrentPW(e.target.value)
              onFieldChange()
            }}
            type={showCurrent ? "text" : "password"}
            value={currentPW}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => setShowCurrent(!showCurrent)}>
                    {showCurrent ? (
                      <Visibility className={classes.svg} />
                    ) : (
                      <VisibilityOff className={classes.svg} />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <div className="new-pw">
            <div className="legend">
              <p>Min of 8 characters</p>
            </div>
            <TextField
              className={classes.input}
              fullWidth
              placeholder="Enter new password"
              onChange={e => {
                setNewPW(e.target.value)
                onFieldChange()
              }}
              type={showNew ? "text" : "password"}
              value={newPW}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowNew(!showNew)}>
                      {showNew ? (
                        <Visibility className={classes.svg} />
                      ) : (
                        <VisibilityOff className={classes.svg} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              className={classes.input}
              fullWidth
              placeholder="Re-enter new password"
              onChange={e => {
                setNewPW2(e.target.value)
                onFieldChange()
              }}
              type={showRe ? "text" : "password"}
              value={newPw2}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowRe(!showRe)}>
                      {showRe ? (
                        <Visibility className={classes.svg} />
                      ) : (
                        <VisibilityOff className={classes.svg} />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </div>
        </div>

        <div className="modal-actions">
          <Button
            className="confirm"
            variant="contained"
            onClick={onClickSubmit}
            disabled={loading || disabled}
          >
            {loading ? <CircularProgress /> : "Update Password"}
          </Button>
        </div>
      </div>
    </Paper>
  )
}

export default ChangePassword
